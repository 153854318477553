// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alumni-js": () => import("./../../../src/pages/alumni.js" /* webpackChunkName: "component---src-pages-alumni-js" */),
  "component---src-pages-care-js": () => import("./../../../src/pages/care.js" /* webpackChunkName: "component---src-pages-care-js" */),
  "component---src-pages-clinicaltrials-js": () => import("./../../../src/pages/clinicaltrials.js" /* webpackChunkName: "component---src-pages-clinicaltrials-js" */),
  "component---src-pages-departments-js": () => import("./../../../src/pages/departments.js" /* webpackChunkName: "component---src-pages-departments-js" */),
  "component---src-pages-experts-js": () => import("./../../../src/pages/experts.js" /* webpackChunkName: "component---src-pages-experts-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-give-js": () => import("./../../../src/pages/give.js" /* webpackChunkName: "component---src-pages-give-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-newsfeed-js": () => import("./../../../src/templates/newsfeed.js" /* webpackChunkName: "component---src-templates-newsfeed-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-pastevents-js": () => import("./../../../src/templates/pastevents.js" /* webpackChunkName: "component---src-templates-pastevents-js" */),
  "component---src-templates-portal-js": () => import("./../../../src/templates/portal.js" /* webpackChunkName: "component---src-templates-portal-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

